@font-face {
    font-family: 'Memphis';
    font-style: normal;
    font-weight: 700;
    src: local('Memphis Extra Bold'), local('MemphisExtraBold'),
         url('./assets/fonts/memphis/memphis-700.ttf') format('truetype'),
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Bold'), local('RobotoBold'),
         url('./assets/fonts/roboto/roboto-400.ttf') format('truetype'),
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('RobotoBold'),
         url('./assets/fonts/roboto/roboto-700.ttf') format('truetype'),
}

html {
    overflow: hidden;
    position: fixed;
}
body {
    font-family: 'Roboto', serif;
    overflow: hidden;
    position: fixed;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'Memphis', serif;
    font-weight: 700;
}

.wrapper {
    max-width: 60rem;
    margin: 0 auto;
    position: relative;
}

.wrapper-condensed {
    max-width: 52rem;
    margin: 0 auto;
    position: relative;
}